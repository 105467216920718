div {
    font-size: 14px !important;
}

.qty-input {
    width: 120px !important;
}

.qty-input > input[type="text"] {
    width: 120px !important;
}

.product-item .on-sale-badge {
    top: 25px !important;
    right: 0px !important;
    line-height: 48px;
}

.name {
    height: 25px !important;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer !important;
}

.quick-view-product-header {
    text-transform: none !important;
}

.products-section > .container {
    background-color: #ecece626 !important;
}

.products-header {
    padding: 15px;
    font-size: 24px !important;
    text-transform: none !important;
    /* font-weight: 600; */
    border-bottom: 1px solid #c2d1af !important;
    color: grey !important;
}

.out-of-stock {
    color: #dc3545 !important;
}

.product-item > .img > .quickview {
    color: white !important;
    background-color: #4dd0e1 !important;
}

.react-multi-carousel-list > button:focus {
    outline: none !important;
    outline: none !important;
}

.padding-top-10 {
    padding-top: 10px;
}

.product-item .action .btn {
    min-width: 130px !important;
}

.img-responsive {
    transition: transform 0.2s !important;
}

.img-responsive:hover {
    transform: scale(1.1) !important;
}

.load-more-btn {
    font-size: 14px;
    color: blue;
    text-transform: none !important;
}

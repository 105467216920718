.page-not-found {
    height: 100% !important;
    background-color: #80deea;
    padding: 25px 0px;
    vertical-align: middle;
}

.page-not-found > h5 {
    text-transform: none !important;
}

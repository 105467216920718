.menu-tabs {
    padding: 0px 20px;
}

.menu-tabs > .btn {
    margin-right: 10px;
    height: 35px;
    border-radius: 4px;
    background-color: #009688;
    border-color: #004d40;
    margin-bottom: 5px;
}

.menu-tabs > .btn:active,
.menu-tabs > .btn:focus,
.menu-tabs > .btn:hover {
    background-color: #009688 !important;
    border-color: #004d40 !important;
    box-shadow: none;
}

.menu-tabs > .active-tab {
    background-color: #039be5 !important;
    border-color: #039be5 !important;
    box-shadow: none;
}

.menu-tabs > .active-tab:active,
.menu-tabs > .active-tab:focus,
.menu-tabs > .active-tab:hover {
    background-color: #039be5 !important;
    border-color: #039be5 !important;
    box-shadow: none;
}

.padding-20 {
    padding: 0px 20px;
}

.btn-action-group > .btn {
    margin-right: 4px;
}

.logout-btn {
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #004d40;
    margin-bottom: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.margin-right-5 {
    margin-right: 5px;
}

.errorMessage {
    padding: 5px 0px;
    color: #f44336;
    font-size: 12px;
}

.form-control {
    min-height: 35px !important;
    font-size: 13px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.cart-items {
    padding: 10px;
    border-bottom: 1px solid #c2d1af !important;
    margin: 0px !important;
}

.cart-products-section {
    background-color: #ecece626 !important;
    margin: 0px 0px 10px 15px !important;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.cart-products-header {
    padding: 15px;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid #c2d1af !important;
}

.cart-product-h5 {
    font-size: 16px;
    text-transform: none !important;
}

.cart-items-dull-font {
    color: #878787 !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.cart-qty-input {
    display: inline-block;
    padding: 3px 6px;
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #c2c2c2;
    margin: 0 5px;
}

.cart-qty-container {
    display: flex !important;
    margin: 10px 0px !important;
}

.qty-dec,
.qty-inc {
    width: 40px;
    height: 28px;
    background: linear-gradient(#fff, #f9f9f9) !important;
    display: inline-block;
    border: 1px solid #c2c2c2;
    cursor: pointer;
    font-size: 16px;
    border-radius: 50% !important;
    padding-top: 1px;
    line-height: 1;
    margin-top: 3px;
    color: #6c757d !important;
}

.qty-dec:disabled {
    background: #ececec !important;
}

.qty-dec:focus,
.qty-inc:focus,
.qty-dec:active,
.qty-inc:active {
    background-color: none !important;
    box-shadow: none !important;
    outline: none !important;
    outline-offset: 0px !important;
}

.remove-btn {
    display: inline-block;
    text-transform: uppercase;
    box-shadow: none;
    outline: 0;
    font-size: 14px !important;
    font-weight: 500 !important;
    cursor: pointer;
    margin-right: 25px;
    margin-top: 25px;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #bbbbbb;
}

.remove-btn:hover {
    color: #2874f0;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-0 {
    margin: 0px !important;
}

.padding-0 {
    padding: 0px !important;
}

.padding-0-10 {
    padding: 0px 10px 0px 0px !important;
}

.checkout-container {
    /* position: sticky !important;
    top: 74px;
    bottom: 0;
    z-index: 2;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start; */
}

.padding-10 {
    padding: 10px !important;
}

.request-btn {
    width: 100%;
    height: 35px;
    font-size: 15px !important;
    text-transform: none !important;
    font-weight: 700 !important;
}

.cart-container {
    /* display: flex; */
    width: 100%;
    /* flex-flow: row; */
}

.error-message-block {
    padding: 20px;
    text-align: center;
}

.padding-20-bottom {
    padding: 20px;
}

.navbar-nav {
    display: block !important;
}

.navbar-nav .dropdown-menu {
    position: absolute !important;
    float: none;
}

.carousel-control-next,
.carousel-control-prev:focus {
    box-shadow: none !important;
}

.dropdown-menu {
    background-color: #4fc3f7 !important;
    border: none !important;
    padding: 0 !important;
    width: 100%;
}

.dropdown-menu > li > span {
    color: #fff;
    padding: 10px 15px;
    font-size: 12px;
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
}

.dropdown-menu > li > span:hover {
    color: #fff;
    background-color: #03a9f4;
}

.pageLoader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000002;
    background: rgba(95, 93, 93, 0.49);
}

.loader {
    top: 40%;
    position: fixed;
    left: 45%;
    /* width: 60px;
    height: 60px; */
}

.navbar-wrapper {
    background-color: #2196f3 !important;
}

.navbar,
.navbar-inverse {
    background-color: #2196f3 !important;
}

.navbar-inverse .navbar-nav > li > span:hover,
.navbar-inverse .navbar-nav > li > span:focus {
    color: #fff;
    background-color: #64b5f6 !important;
}

.navbar-nav > li > span {
    position: relative;
    display: block;
    color: #fff;
    /* font-weight: 600; */
    letter-spacing: 0.025em;
    /* text-transform: uppercase; */
    font-size: 18px;
    line-height: 1;
    min-height: 0;
    padding: 18px;
    cursor: pointer;
}

.minicart-inner-top {
    cursor: pointer;
}

.copyright {
    padding: 20px 0;
    color: #fff;
    font-size: 12px;
    background: #2196f3 !important;
    background-color: #2196f3 !important;
}

.extrapages-shower > a {
    background: #2196f3 !important;
    background-color: #2196f3 !important;
}

.btn-default,
.btn-inverse,
.btn-primary {
    background: #2196f3c9 !important;
    background-color: #2196f3c9 !important;
    border-color: #2196f3c9 !important;
}

.dropdown-menu {
    max-height: 50vh;
    overflow: auto;
    scrollbar-width: thin !important;
}

::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
